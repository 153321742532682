@import "../styles/global.module";

.lHeader{
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px 48px;
  width: 100%;
  z-index: 1000;
  line-height: 1;
  transition: background-color 0.6s;

  &Fixed{
    box-shadow: 0 0 6px 2px rgb(0 0 0 / 25%);
    background-color: $color-white;
    z-index: 1002;
  }

  @include mqMax($break-m){
    padding: 24px 6.4vw;
  }

  &Active {
    @include mqMax($break-l){
      z-index: 1002;
      height: 100%;
      width: 100%;
      background-color: $color-white;
    }
  }

  &Logo{
    float: left;

    a {
      img{
        width: 150px;
      }
    }
    @include mqMax($break-s){
      a {
        img {
          width: 28.8vw;
        }
      }
    }

    &Active {
      @include mqMax($break-l){
        margin-bottom: 30px;
      }
    }
  }

  &OpenCloseButton {
    display: none !important;

    @include mqMax($break-l){
      display: block !important;
      z-index: 10;
      position: absolute;
      top: 35px;
      right: 48px;
      width: 28px;
      height: 24px;
      background: none;
      border: none;
      appearance: none;
      cursor: pointer;

      &, span {
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
      }

      span {
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #000;
        border-radius: 4px;
      }

      span:nth-of-type(1) {
        top: 0;
      }

      span:nth-of-type(2) {
        top: 10px;
      }

      span:nth-of-type(3) {
        bottom: 0;
      }

      &Active {
        span:nth-of-type(1) {
          transform: translateY(11px) rotate(-45deg);
        }
        span:nth-of-type(2) {
          opacity: 0;
        }
        span:nth-of-type(3) {
          transform: translateY(-10px) rotate(45deg);
        }
      }
    }

    @include mqMax($break-m){
      top: 30px;
      right: 6.4vw;
      width: 22px;
      height: 20px;

      span:nth-of-type(2) {
        top: 8px;
      }

      &Active {
        span:nth-of-type(1) {
          transform: translateY(9px) rotate(-45deg);
        }
        span:nth-of-type(3) {
          transform: translateY(-8px) rotate(45deg);
        }
      }
    }
  }
}

.lGNav{
  float: right;
  display: block;

  &Cv{
    float: left;

    & > a:first-child{
      display: inline-block;
      padding: 0.95em 1.4em 0.85em;
      font-size: 16px;
      font-weight: 700;
      color: $color-white;
      background-color: $color-black;
      border-radius: 50px;
      line-height: 1;
      transition: $base-transition;

      &:hover{
        color: $color-black;
        background-color: $color-yellow;
      }

      @include mqMax($break-m){
        font-size: 2.8vw;
      }

      @include mqMax($break-s){
        font-size: 3.7vw;

        &:hover{
          color: $color-white;
          background-color: $color-black;
        }
      }
    }
  }

  @include mqMax($break-l) {
    display: none;
    width: 100%;
    height: 100%;
  }

  &Active {
    @include mqMax($break-l) {
      display: block;

      &Cv{
        float: none;
      }
    }
  }
}
