@import "../styles/global.module";

.lAbout{
  @extend %clearfix;

  padding-top: 100px;

  .headline{
    text-align: left;

    strong {
      font-family: $font-en;

      span {
        display: inline;
      }
    }

    &CurrencySymbol {
      padding-left: 4px;
      font-size: 0.7em;
    }

    &Currency {
      font-size: 1.3em;
    }

    &ReferenceMark {
      padding-right: 4px;
      font-size: 12px;
    }
  }

  &Title{
    position: relative;
    float: left;
    width: 31.46%;

    span{
      position: absolute;
      bottom: -0.7em;
      right: 0;
      font-size: 24px;
      font-weight: 700;
    }
  }

  &Text{
    position: relative;
    float: right;
    width: 57.16%;

    & > p {
      &.note {
        font-size: 13px;
        text-align: right;
        margin-top: 47px;
        text-indent: -1.4em;
        margin-left: 1.4em;
      }
    }
  }

  &Feature{
    margin-top: 40px;
    font-weight: 700;
    text-align: center;

    figure{
      display: block;
      margin-bottom: 0.6em;
      background-color: $color-yellow;
      border-radius: 100%;
    }
  }

  &En{
    position: absolute;
    top: 70px;
    right: -70px;
    font-family: $font-en;
    font-size: 20px;
    font-weight: 700;
    transform: rotate(90deg);
  }
  @include mqMax(1180px){
    &En{
      display: none;
    }
  }

  @include mqMax($break-m){
    padding-top: 15vw;

    &Title{
      float: none;
      margin: 0 auto 8vw;
      width: 55vw;

      span{
        right: -0.25em;
        font-size: 3.5vw;
      }
    }

    &Text{
      float: none;
      width: 100%;
    }

    &Feature{
      margin-top: 7vw;
      font-size: 2.2vw;
    }
  }
  @include mqMax($break-s){
    &Title{
      margin: 0 auto 10vw;
      width: 74.6vw;

      span{
        font-size: 4.3vw;
      }
    }

    &Text{
      & > p {
        &.note {
          text-align: left;
        }
      }
    }

    &Feature{
      margin-top: 10vw;
      font-size: 3.5vw;

      figure{
        margin-bottom: 0.4em;
      }

      li{
        margin-right: 6%;
        width: 47%;

        &:nth-child(1){
          float: none;
          margin: 0 auto 6.4vw;
        }

        &:nth-child(2){
          clear: left;
        }

        &:nth-child(3){
          margin-right: 0;
        }
      }
    }
  }
}
