@import "libs/presentation/src/lib/components/common/cssModules/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";


.outline {
  margin: 0 auto;
  padding-top: 25px;
  max-width: 1368px;
  width: 100%;

  &Contents {
    margin-bottom: 100px;
    padding: 0 49px;
    min-width: 310px;
  }
}

.error {
  &Message {
    margin-bottom: 24px;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
  }

  &Lead {
    margin-bottom: 62px;
    text-align: center;
  }
}

.button {
  margin-bottom: 18px;
}

.link {
  a {
    text-decoration: underline !important;
    color: #D5AC00 !important;

    &:hover {
      cursor: pointer;
      color: #666 !important;
    }
  }
}

@include mqMax($break-m) {
  .outline {
    &Contents {
      padding: 0 32px;
    }
  }

  .error {
    &Message {
      font-size: 26px;
    }

    &Lead {
      font-size: 14px;
    }
  }
}

@include mqMax($break-s) {
  .error {
    &Lead {
      text-align: left;
    }
  }
}
