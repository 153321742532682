@import "../styles/global.module";

.lApply {
  background-color: $color-yellow;
  padding-top: 80px;
  padding-bottom: 80px;

  > p {
    text-align: center;

    &.note {
      margin-top: 3em;
      font-size: 15px;
    }
  }

  > button {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 88px;
    font-size: 24px;
    font-weight: 700;
    line-height: 88px;
    color: $color-white;
    text-align: center;
    background-color: $color-orange;
    border-radius: 8px;
    transition: $base-transition;

    span {
      display: inline-block;
      margin-right: 0.5em;
      padding-left: 0.05em;
      width: 36px;
      height: 36px;
      font-family: $font-en;
      color: $color-orange;
      line-height: 38px;
      background-color: $color-white;
      border-radius: 36px;
    }

    &:hover {
      background-color: $color-orange-darken;
    }
  }

  @include mqMax($break-ms){
    & > p{
      &.note{
        text-align: left;
      }
    }
  }
}
