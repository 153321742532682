@keyframes float_badge {
  0%		{ bottom: 285px;}
  50%		{ bottom: 275px;}
  100%	{ bottom: 285px;}
}

@keyframes float_badge_tab {
  0%		{ bottom: 35vw;}
  50%		{ bottom: 36vw;}
  100%	{ bottom: 35vw;}
}

@keyframes float_badge_sp {
  0%		{ top: -20.6vw;}
  50%		{ top: -22.6vw;}
  100%	{ top: -20.6vw;}
}
