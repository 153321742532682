@import "libs/presentation/src/lib/components/common/cssModules/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.footer {
  padding: 0 80px 40px;
  text-align: right;
  font-weight: bold;
  font-size: 12px;
  background-color: #F2F0E9;

  &CommonStyle {
    background-color: #fff;
  }
}

@include mqMax($break-m) {
  .footer {
    text-align: center;
    margin: 0 0 42px;
  }
}
