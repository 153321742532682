@import "src/components/lp/styles/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.device{
  &Pc{
    @include mqMax($break-m){
      display: none !important;
    }
  }

  &TabL{
    display: none;
    @include mqMax($break-l){
      display: inline-block !important;
    }
  }

  &Tab{
    display: none;
    @include mqMax($break-m){
      display: inline-block !important;
    }
  }

  &Sp{
    display: none !important;
    @include mqMax($break-s){
      display: inline-block !important;
    }
  }
}
