.lpWrapper{
  overflow-x: hidden;
  position: relative;

  img{
    vertical-align: bottom;
  }

  ul,ol{
    list-style: none;

    li{
      list-style: none;
    }
  }

  i, em{
    font-style: normal;
  }

  a{
    color: #000;
    transition: ease .3s;

    i{
      display: inline-block;
      transition: ease .3s;
    }
  }

  h1,h2,h3,h4,h5{
    font-weight: normal;
  }

  ::selection{
    color: #000;
    background-color: #fff;
  }

  ::selection{
    color: #000;
    background-color: #fed900;
  }

  .en{
    font-family: Montserrat, serif;
  }

  .l-wrapper{
    overflow-x: hidden;
    position: relative;
  }

  .inner{
  }

  .section{
  }

}
