@import "libs/presentation/src/lib/components/common/cssModules/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.header {
  margin: 0 49px 140px;
}

@include mqMax($break-m) {
  .header {
    margin: 0 23px 80px;
    width: auto;
  }
}
