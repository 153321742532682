@import "src/components/lp/styles/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.gnav{
  @extend %clearfix;

  float: left;

  li{
    float: left;
    margin-right: 40px;

    a{
      position: relative;
      display: inline-block;
      padding: 1em 0 0.9em;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;

      &::before{
        position: absolute;
        left: 50%;
        bottom: 0.5em;
        display: block;
        content: "";
        width: 0;
        height: 2px;
        background-color: $color-black;
        transform: translateX(-50%);
        transition: $base-transition;
      }

      &:hover{
        &::before{
          width: 100%;
        }
      }
    }
  }

  &Active {
    @include mqMax($break-l) {
      float: none;
      margin-bottom: 20px;

      li {
        float: none;
      }
    }
  }
}
