@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.section{
  padding: 120px 8.18%;

  @include mqMax($break-m){
    padding: 19vw 8.53vw 21vw;
    min-width: auto;
  }
}
