@import "../styles/global.module";

.lModelCase{
  position: relative;

  .headline {
    font-weight: bold;
  }

  .lead {
    text-align: center;
  }

  &::before{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100vw;
    height: 100%;
    background-color: $color-yellow-lighten;
  }

  & > h2 {
    position: relative;
  }

  & > p {
    position: relative;
    text-align: center;
  }

  &Flow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 56px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;

    li{
      position: relative;
      width: 30%;

      //counter-increment: num;
      &::after{
        position: absolute;

        //content: "0"counter(num);
        top: -0.3em;
        left: 0;
        font-size: 56px;
        font-weight: 700;
        font-family: $font-en;
        color: $color-yellow-darken;
      }

      &>h3{
        background-color: $color-yellow;
        padding: 25px 44px 15px;
        line-height: 23px;
        font-size: 16px;
        font-weight: 700;
      }
    }

    &TableWrapper{
      background-color: $color-white;
      padding: 28px 44px;

      &>table{
        margin: auto;
        width: 100%;

        tr:not(:last-child) {
          td {
            padding-bottom: 17px;
          }
        }

        td{
          text-align: left;
          font-size: 15px;

          &:nth-child(odd) {
            width: 90px;
          }

          &.label{
            font-weight: normal;
          }

          &.value{
            font-weight: 700;
          }
        }
      }
    }

    &Summary{
      padding: 32px 0;
      line-height: 1;

      .formula{
        font-size: 13px;
      }

      .price{
        font-size: 18px;
        color: $color-orange;
        line-height: 1.2;

        span{
          font-family: $font-en;
          font-size: 40px;
        }
      }

      .storageLocation{
        font-size: 16px;
      }
    }
  }

  &Simulator{
    position: relative;
    background-color: $color-white;
    margin-top: 64px;
    padding: 26px 98px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > p {
      vertical-align: middle;
      margin: auto 0;
      font-size: 15px;
    }

    & > button {
      cursor: pointer;
      width: 360px;
      height: 88px;
      font-size: 24px;
      font-weight: 700;
      line-height: 88px;
      color: $color-white;
      text-align: center;
      background-color: $color-orange;
      border-radius: 8px;
      transition: $base-transition;

      span {
        display: inline-block;
        margin-right: 0.5em;
        padding-left: 0.05em;
        width: 36px;
        height: 36px;
        font-family: $font-en;
        color: $color-orange;
        line-height: 38px;
        background-color: $color-white;
        border-radius: 36px;
      }

      &:hover {
        background-color: $color-orange-darken;
      }
    }
  }
  @include mqMax(1314px){
    &Simulator{
      & > p {
        margin-bottom: 20px;
      }

      & > button {
        width: 100%;
      }
    }

    &Flow{
      justify-content: space-evenly;

      li {
        width: 360px;

        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }
    }
  }
  @include mqMax($break-m){
    &Flow{
      margin-top: 10vw;
      font-size: 2.4vw;
      justify-content: space-evenly;

      li{
        &::after{
          top: -0.2em;
          font-size: 8vw;
        }

        &>h3{
          padding: 16px 28px;
        }
      }


      &TableWrapper{
        padding: 24px 28px;
      }
    }
  }
  @include mqMax($break-ms){
    .lead {
      text-align: left;
    }
  }
  @include mqMax($break-s){
    &Simulator{
      padding: 24px 28px;

      & > p {
        margin-bottom: 14px;
        font-size: 14px;
      }
    }
    &Flow{
      font-size: 3.2vw;
    }
  }
}
