@import "../styles/global.module";


.lStep{
  position: relative;

  .headline {
    font-weight: bold;
  }

  &::before{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100vw;
    height: 100%;
    background-color: $color-yellow-lighten;
  }

  & > h2 {
    position: relative;
    font-weight: bold;
  }

  & > p {
    position: relative;

    &.note {
      font-size: 13px;
      text-align: right;
      margin-top: 67px;
      text-indent: -1.4em;
      margin-left: 1.4em;
    }
  }

  &Flow{
    margin-top: 56px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;

    li{
      position: relative;
      counter-increment: num;

      &::after{
        position: absolute;
        content: "0"counter(num);
        top: -0.3em;
        left: 0;
        font-size: 56px;
        font-weight: 700;
        font-family: $font-en;
        color: $color-yellow-darken;
      }
    }

    figure{
      display: block;
      margin-bottom: 0.6em;
      background-color: $color-yellow;
      border-radius: 100%;
    }
  }
  @include mqMax($break-m){
    &Flow{
      margin-top: 10vw;
      font-size: 2.4vw;

      li{
        &::after{
          top: -0.2em;
          font-size: 8vw;
        }
      }
    }
  }
  @include mqMax($break-s){
    & > p {
      &.note {
        margin-top: 40px;
        text-align: left;
      }
    }

    &Flow{
      font-size: 3.2vw;
    }
  }
}
