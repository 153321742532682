@import "../styles/global.module";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.lSimulator{
  display: none;
  opacity: 0;
  overflow: auto;
  z-index: 19999;
  position: fixed;
  left: 0;
  top: 0;
  padding: 80px 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-yellow, 0.9);

  &.visible {
    animation: fadeIn 0.3s linear 0s;
    display: block;
    opacity: 1;
  }

  &Close{
    margin: 0 auto 42px;
    width: 1144px;
    text-align: right;

    & > a {
      &:hover {
        cursor: pointer;
      }

      & > img {
        width: 42px;
        height: 42px;
      }
    }
  }

  &Dialog {
    margin: 0 auto;
    border: 3px solid $color-black;
    border-radius: 16px;
    padding: 80px 98px;
    width: 1144px;
    min-width: 840px;
    background-color: $color-yellow-lighten;
    transition: $base-transition;

    & > h2.headline {
      //font-size: 32px;
      font-weight: 700;
    }

    & > p {
      text-align: center;
    }

    .form {
      &Label {
        p {
          margin-right: 10px;
        }
      }

      &Checkbox {
        padding: 14px 20px 14px 50px !important;
        min-height: auto;

        &::before{
          top: 13px;
        }

        &::after{
          top: 14px;
        }
      }

      &Content {
        input{
          &:focus{
            background-color: $color-white;
          }
        }

        ul {
          display: inline-flex;
          flex-wrap: wrap;
          margin-right: -22px;
          margin-bottom: -22px;

          li {
            margin: 0 22px 22px 0;
          }
        }

        &ShippingQuantity {
          display: inline-flex;

          :global(.MuiFormControl-root) {
            width: 186px;
          }

          &Unit {
            padding-top: 14px;
            width: 70px;
            text-align: center;
            vertical-align: middle;
          }
        }

        &ShippingPercentages {
          &Unit {
            width: 30px !important;
            text-align: center;
          }
        }

        & ul {
          display: inline-flex;

          li {
            display: inline-flex;

            p {
              display: inline;
              padding-top: 16px;
              width: 100px;
            }

            & :global(.MuiFormControl-root) {
              width: auto;

              input{
                width: 40px;
              }
            }
          }
        }
      }
    }

    &Estimate {
      &Form {
        margin-bottom: 24px;
      }
    }

    &Result {
      margin-bottom: 35px;
      padding: 0;
      border-top: 3px dashed $color-black;
      text-align: center;

      h3{
        margin-bottom: 0.8em;
        font-size: 24px;
        font-weight: 700;
      }

      &Price{
        margin: 40px 0;
        font-size: 20px;
        font-weight: 700;

        &Num{
          margin: 12px auto;
          padding: 0.5em 0;
          background-color: $color-white;
          border-radius: 8px;

          strong{
            color: $color-orange;
            font-size: 36px;
            font-family: $font-en;
          }
        }
      }

      &Note {
        margin-top: 30px;
        margin-left: 1em;
        text-indent: -1em;
        font-size: 12px;
        text-align: left;
      }
    }
  }

  // MUI 上書き
  & {
    fieldset {
      border: none;
    }
  }
}

// 1208 = 1144 + (32 * 2)
@include mqMax(1208px){
  .lSimulator {
    min-width: auto;
    &Close{
      width: 92%;
    }
    &Dialog {
      width: 92%;
      min-width: 312px;

      .form {
        &Label,
        &Content {
          float: none;
          width: 100%;
        }

        &Label {
          display: inline-flex;
          line-height: 2em;
          flex-wrap: wrap;
          margin-bottom: 16px;
        }

        &Content {
          margin-bottom: 32px;
        }
      }
    }
  }
}

@include mqMax($break-m){
  .lSimulator {
    padding: 40px 32px;
    &Dialog {
      padding: 80px 24px;
      > p {
        &.note{
          font-size: 2vw;
        }
      }

      .form {
        &Label {
          p {
            margin: 0 0 -16px;
          }
        }
      }

      &Estimate{
        margin: 0 auto;
        width: 100%;
        min-width: 100%;
      }

      &Contact{
        width: 100%;
        min-width: 100%;

        &Form{
          padding: 10vw 5.5vw;

          .form {
            &Label,
            &Content{
              width: 100%;
            }
          }
        }

        &Result{
          padding: 7vw 0 8vw;

          h3{
            margin-bottom: 0.8em;
            font-size: 3.8vw;
          }

          &Place{
            padding: 0 5.5vw;

            li{
              display: block;
              margin: 0 0 0.5em;
              width: 100%;
              font-size: 2.8vw;

              &:last-child{
                margin-bottom: 0;
              }
            }
          }

          &Price{
            margin-top: 6vw;
            padding: 0 5.5vw;
            font-size: 2.8vw;

            &Num{
              margin: 0.5em auto;
              width: 100%;

              strong{
                font-size: 6vw;
              }
            }
          }
        }

        &Input{
          margin-top: 6vw;

          .clm3{
            li{
              margin-right: 4%;
              width: 48%;

              &:nth-child(2){
                margin-right: 0;
              }
            }
          }
        }

        &Confirm{
          .formLabel,
          .formContent{
            margin-bottom: 0;
            padding: 0 1em;
            font-size: 2.8vw;
          }

          .formLabel{
            padding-top: 1em;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 0;
          }

          .formContent{
            margin-bottom: 0.5em;
            padding-bottom: 0.8em;
            height: auto;
            line-height: 1.8;
            border-top-right-radius: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }

          .formBtn{
            margin-top: 3vw;
          }
        }

        &Complete{
          text-align: left;
        }
      }
    }
  }
}

.note {
  margin-bottom: 43px;
  text-align: center;
}
