.loading {
  z-index: 20000001;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;

  > * {
    position: fixed;
    inset: 0;
    margin: auto;
  }
}
