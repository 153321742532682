@import "../styles/global.module";

.lMore{
  margin-bottom: 145px;
  padding-bottom: 0px;

  p{
    text-align: center;
  }

  &More{
    position: relative;
    margin-top: 80px;
    padding: 74px 0 80px;

    &>h2{
      position: relative;
      font-weight: bold;

      .noMargin {
        margin: 0;
      }
    }

    &>p{
      position: relative;
      font-size: 11px;
      margin-top: 35px;
    }

    &::before{
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background-color: $color-yellow-lighten;
      border-radius: 16px;
    }

    &Label{
      position: absolute;
      top: -1em;
      left: 50%;
      padding: 0.5em 2.5em;
      font-size: 26px;
      line-height: 1;
      font-weight: 700;
      border-radius: 8px;
      transform: translateX(-50%);
      background-color: $color-yellow;
    }

    &ListCardWrapper{
      position: relative;
      padding: 0 6em;

      .clm2 {
        display: flex;
        flex-wrap: wrap;
      }

      .listCard {
        background-color: $color-white;

        h3{
          background-color: $color-yellow;
        }

        .headline{
          font-size: 22px;
          color: $color-orange-darken;

          strong {
            text-decoration: solid 8px underline $color-yellow;
            text-underline-offset: -8px;

            &:before {
              position: initial;
              bottom: 0;
              left: 0;
              content: none;
            }
          }
        }

        p{
          font-size: 15px;
          text-align: left;
        }

        & > div {
          padding: 35px 32px 38px;
        }
      }
    }

    &Images{
      img{
        position: absolute;
      }

      & > img:first-child{
        bottom: 110px;
        left: -32px;
        width: 196px;
      }

      & > img:last-child{
        bottom: -80px;
        right: -80px;
        width: 475px;
      }
    }

    &Images2{
      img{
        position: absolute;
      }

      & > img:first-child{
        bottom: -18px;
        right: -38px;
        width: 206px;
      }
    }
  }

  @include mqMax($break-l){
    &More{
      &Images{
        & > img:first-child{
          bottom: 90px;
          left: -55px;
          width: 160px;
        }

        & > img:last-child{
          bottom: -60px;
          right: -140px;
          width: 380px;
        }
      }
    }
  }

  @include mqMax($break-m){
    padding-bottom: 0;

    p{
      text-align: left;
    }

    &More{
      margin-top: 5vw;
      padding: 9vw 6.5vw 10vw;

      .headline{
        font-size: 3.8vw;
      }

      &Label{
        font-size: 3.8vw;
      }

      &Images{
        & > img:first-child{top: 5vw;
          bottom: auto !important;
          left: -11vw !important;
          width: 23vw !important;
        }

        & > img:last-child{
          bottom: -8vw !important;
          right: -23vw !important;
          width: 52vw !important;
        }
      }

      &Images2{
        img{
          position: absolute;
        }

        & > img:first-child{
          bottom: -18px;
          right: -52px;
          width: 206px;
        }
      }

      &ListCardWrapper{
        padding: 0;

        li:not(:last-child) {
          margin-bottom: 16px;
        }
        .listCard {
          .headline{
            strong {
              text-decoration: solid 6px underline $color-yellow;
              text-underline-offset: -6px;
            }
          }
        }
      }
    }

    &More2 {
      & > p {
        width: 70%;
        text-indent: -1.1em;
        margin-left: 1.1em;
      }
    }
  }
  @include mqMax($break-s){
    &More{
      margin-top: 5vw;
      padding: 11vw 9vw 10vw;

      &::before{
        border-radius: 8px;
      }

      .headline{
        font-size: 5vw;
      }

      &Label{
        padding: 0.5em 1.5em;
        font-size: 4.6vw;
        border-radius: 4px;
      }

      &Images{
        & > img:first-child{
          top: 4vw;
          left: -15.5vw !important;
          width: 28vw !important;
        }

        & > img:last-child{
          bottom: -10vw !important;
          right: -32vw !important;
          width: 63vw !important;
        }
      }

      &Images2{
        & > img:first-child{
          bottom: 62px;
          right: -17px;
          width: 104px;
        }
      }
    }

    &More2 {
      & > p {
        margin-top: 17px;
        width: 75%;
      }
    }
  }
}


.lMore {
  &More2 {
    margin-top: 140px;
    padding-bottom: 40px;
  }
}
@include mqMax($break-s){
  .lMore {
    margin-bottom: 80px;

    &More2 {
      margin-top: 80px;
    }
  }
}
