@import "src/components/lp/styles/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.form{
  @extend %clearfix;

  &Label,
  &Content{
    float: left;
    margin-bottom: 32px;
  }

  &Label{
    clear: left;
    width: 21%;
    font-size: 20px;
    font-weight: 700;

    span{
      display: block;
      font-size: 11px;
    }
  }

  &Content{
    width: 79%;
    font-size: 18px;

    input,
    label{
      @include placeholder(#888);

      display: inline-block;
      padding: 0 1em;
      min-height: 64px;
      width: 100%;
      background-color: $color-white;
      border: 2px solid $color-black;
      border-radius: 8px;

      &.small{
        width: 18%;
      }

      &.error{
        background-color: $color-error-lighten;
        @include placeholder($color-error);
      }
    }

    input{
      &:focus{
        outline: none;
        background-color: $color-yellow-lighten;
      }
    }

    span{
      display: block;
      margin: 0.5em 0;
      font-size: 12px;

      &.error{
        color: $color-error;
      }
    }

    .clm4.place{
      input{
        width: 27%;
        padding: 0 8px;
        appearance: none;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
      }
    }
  }

  &Btn{
    button{
      display: block;
      width: 100%;
      height: 88px;
      font-size: 24px;
      font-weight: 700;
      line-height: 88px;
      color: $color-white;
      text-align: center;
      background-color: $color-orange;
      border-radius: 8px;
      transition: $base-transition;

      span{
        display: inline-block;
        margin-right: 0.5em;
        padding-left: 0.05em;
        width: 36px;
        height: 36px;
        font-family: $font-en;
        color: $color-orange;
        line-height: 38px;
        background-color: $color-white;
        border-radius: 36px;
      }

      &:hover{
        background-color: $color-orange-darken;
      }
    }
  }

  &Checkbox{
    position: relative;
    padding: 0 0 0 50px !important;

    &::before,
    &::after{
      position: absolute;
      display: block;
    }

    &::before{
      top: 16px;
      left: 12px;
      content: "";
      width: 28px;
      height: 28px;
      background-color: $color-yellow-lighten;
      border-radius: 4px
    }

    &::after{
      opacity: 0;
      top: 20px;
      left: 16px;
      content: url(/images/form/icon_check.svg);
    }
  }

  &Policy{
    overflow: auto;
    margin-bottom: 24px;
    padding: 2em;
    height: 14.5em;
    font-size: 15px;
    background-color: $color-white;
    border-radius: 8px;

    h4{
      margin-top: 2em;
      padding-top: 2em;
      margin-bottom: 1em;
      font-size: 18px;
      font-weight: 700;
      border-top: 1px solid $color-grey-lighten;

      &:first-child{
        margin-top: 0;
        padding-top: 0;
        border: none;
      }
    }

    h5{
      margin-top: 2em;
      margin-bottom: 1em;
      font-size: 16px;
      font-weight: 700;

      &:first-child{
        margin-top: 0;
      }
    }

    p{
      margin-bottom: 1em;
      line-height: 1.8;
    }

    ol{
      li{
        position: relative;
        margin-bottom: 0.8em;
        padding-left: 1.4em;
        line-height: 1.8;
        counter-increment: num;

        &::after{
          position: absolute;
          content: counter(num) ". ";
          top: 0;
          left: 0;
        }
      }
    }

    table{
      border-collapse: collapse;

      thead{
        th, td{
          background-color: $color-grey-lighten;
        }
      }

      th,td{
        padding: 1em;
        width: 50%;
        border: 1px solid $color-grey;
      }
    }
  }

  input{
    &[type="checkbox"]{
      display: none;
    }

    &:checked{
      &+label{
        background-color: $color-yellow;

        &::after{
          opacity: 1;
        }
      }
    }
  }

  @include mqMax($break-m){
    &Label,
    &Content{
      float: none;
      margin-bottom: 7vw;
    }

    &Label{
      margin-bottom: 1.5vw;
      width: 100%;
      height: auto;
      font-size: 3.2vw;

      span{
        display: inline;
        margin-top: 0;
        font-size: 2vw;
      }
    }

    &Content{
      width: 100%;

      input,
      label{
        display: inline-block;
        padding: 0 1em;
        width: 100%;

        &.small{
          margin-bottom: 0.5em;
          width: 48%;
        }
      }

      span{
        font-size: 2vw;
      }

      .clm4{
        li{
          margin-bottom: 4% !important;
        }

        &.place{
          input{
            width: 70%;
          }
        }
      }
    }

    &Btn{
      button{
        height: 13vw;
        font-size: 4.2vw;
        line-height: 13vw;

        span{
          margin-right: 0.5em;
          padding-right: 0.05em;
          width: 6vw;
          height: 6vw;
          line-height: 6.5vw;
          border-radius: 6vw;
        }

        &:hover{
          background-color: $color-orange;
        }
      }
    }

    &Checkbox{
      padding: 0 0 0 9vw !important;
    }
  }


  @include mqMax($break-s){
    &Label,
    &Content{
      margin-bottom: 7vw;
    }

    &Label{
      margin-bottom: 1.5vw;
      font-size: 4.8vw;

      span{
        font-size: 2.8vw;
      }
    }

    &Content{
      span{
        font-size: 2.8vw;
      }

      .clm4{
        &.place{
          input{
            width: 56%;
          }
        }
      }
    }

    &Btn{
      button{
        height: 19vw;
        font-size: 5.6vw;
        line-height: 19vw;

        span{
          width: 9vw;
          height: 9vw;
          line-height: 9.5vw;
          border-radius: 9vw;
        }
      }
    }

    &Checkbox{
      padding: 0 0 0 11vw !important;
    }
  }
}
