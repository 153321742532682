@import "../styles/global.module";

.lHowto{
  position: relative;

  &::before{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100vw;
    height: 100%;
    background-color: $color-yellow-lighten;
  }

  &>h2{
    position: relative;

    &.headline {
      margin-bottom: 64px;
      font-weight: bold;
    }
  }

  &>p{
    position: relative;
    text-align: center;

    &.note{
      margin-top: 1em;
      font-size: 11px;
    }
  }

  &Step{
    margin-bottom: 57px;

    li{
      position: relative;
      counter-increment: num;

      &::after{
        position: absolute;
        content: "0"counter(num);
        top: -0.6em;
        left: 0.5em;
        font-size: 56px;
        font-weight: 700;
        font-family: $font-en;
        color: $color-yellow-darken;
      }

      figure{
        background-color: $color-white;
        border-radius: 16px;
      }

      h3{
        margin: 1em 0 0.7em;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  @include mqMax($break-l){
    &Step{
      li{
        &::after{
          font-size: 40px;
        }
      }
    }
  }

  @include mqMax($break-m){
    &Step{
      margin-top: 8vw;

      li{
        margin-right: 4%;
        width: 48%;

        &::after{
          top: -0.6em;
          left: 0.5em;
          font-size: 6vw !important;
        }

        &:first-child{
          float: none;
          margin: 0 auto 10vw;
        }

        &:last-child{
          margin-right: 0;
        }

        h3{
          margin: 1em 0 0.7em;
          font-size: 2.8vw;
        }

        p{
          font-size: 2.4vw;
        }
      }
    }
  }
  @include mqMax($break-ms){
    & > p{
      &.note{
        text-align: left;
      }
    }
  }
  @include mqMax($break-s){
    &Step{
      margin-top: 12vw;

      li{
        margin-bottom: 14vw;
        float: none;
        width: 100%;

        &::after{
          top: -0.6em;
          left: 0.5em;
          font-size: 13vw !important;
        }

        &:first-child{
          margin-bottom: 14vw;
        }

        &:last-child{
          margin-bottom: 0;
        }

        h3{
          margin: 1em 0 0.7em;
          font-size: 4.8vw;
        }

        p{
          font-size: 3.8vw;
        }
      }
    }
  }
}
