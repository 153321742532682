@import "src/components/lp/styles/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.headline{
  margin-bottom: 0.8em;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.45;
  text-align: center;

  strong{
    position: relative;
    z-index: 0;

    &::before{
      position: absolute;
      bottom: 0.05em;
      left: 0;
      display: inline;
      content: "";
      width: 100%;
      height: 8px;
      background-color: $color-yellow;
      z-index: -1;
    }
  }

  span{
    display: block;
    margin-top: 0.5em;
  }

  @include mqMax($break-m){
    margin-bottom: 1em;
    font-size: 4.4vw;
    text-align: center;
  }

  @include mqMax($break-s){
    font-size: 5.8vw;

    strong{
      &::before{
        height: 6px;
      }
    }
  }
}
