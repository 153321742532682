@import "../styles/global.module";

.lMv{
  padding: 95px 48px 48px;
  height: 100vh;

  .inner{
    position: relative;
    min-height: 590px;
    height: 100%;
    background-color: $color-yellow-lighten;
  }

  &Text{
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    &Inner{}
  }

  &Tagline{
    margin-bottom: 38px;
    font-size: 25px;
    font-weight: 700;
    text-align: center;

    strong{
      display: inline-block;
      position: relative;
      top: 0.1em;
      margin: 0 0.2em;
      font-family: $font-en;
      font-size: 0.9em;
      padding: 0 0.6em;
      height: 1.6em;
      line-height: 1.6em;
      background-color: $color-yellow;
      border-radius: 6px;

      em{
        font-size: 1.5em;
      }
    }
  }

  &Logo{
    margin: 0 auto 50px;
    width: 200px;
    text-align: center;
  }

  &Cv{
    margin-bottom: 16px;
    text-align: center;

    &Badge{
      display: none;
    }

    a{
      position: relative;
      display: inline-block;
      padding-left: 1.35em;
      width: 294px;
      height: 60px;
      font-size: 22px;
      font-weight: 700;
      line-height: 60px;
      background-color: $color-yellow;
      border-radius: 30px;

      span{
        position: absolute;
        top: 14px;
        left: 16px;
        padding: 0.3em 0 0 0.1em;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 1;
        border-radius: 32px;
        background-color: $color-white;
      }

      &:hover{
        color: $color-white;
        background-color: $color-black;

        span{
          color: $color-black;
        }
      }
    }
  }

  &Topic {
    text-align: center;
    font-size: 16px;

    strong {
      padding: 8px;
      border-radius: 6px;
      background-color: #fed900;

      &::before {
        display: none !important;
      }
    }
  }

  &Images{
    img{
      position: absolute;
    }

    & > img:nth-child(1){
      z-index: 1001;
      top: -68px;
      left: -180px;
      width: 45vw;
    }

    & > img:nth-child(2){
      bottom: -16px;
      right: -16px;
      width: 35vw;
    }

    & > img:nth-child(3){
      bottom: -16px;
      left: 48px;
      width: 250px;
    }

    & > img:nth-child(4){
      position: absolute;
      bottom: 285px;
      right: 120px;
      width: 145px;
      animation: float_badge 2s linear infinite;
    }
  }

  @include mqMax($break-m){
    min-width: auto;
    height: 93.5vh;
    padding: 15.5vw 6.4vw 6.4vw;


    &Images{
      & > img:nth-child(1){
        top: -8vw;
        left: -8vw;
      }

      & > img:nth-child(2){
        bottom: -3vw;
        right: -20vw;
        width: 45vw;
      }

      & > img:nth-child(3){
        bottom: -3vw;
        left: -3vw;
        width: 32vw;
      }

      & > img:nth-child(4){
        bottom: 40vw;
        right: -2.5vw;
        animation: float_badge_tab 2s linear infinite;
      }
    }
  }

  @include mqMax($break-s){
    height: 110vh;
    padding: 17.5vw 6.4vw 6.4vw;

    &Text{
      height: 100%;

      &Inner{
        position: absolute;
        top: 45%;
        width: 100%;
        transform: translateY(-73%);
      }
    }

    &Tagline{
      margin-bottom: 3.5vw;
      font-size: 4vw;

      strong{
        height: 1.8em;
        line-height: 1.8em;
      }
    }

    &Logo{
      margin-bottom: 0;

      img{
        width: 32vw;
      }
    }

    &Cv{
      position: absolute;
      left: 50%;
      bottom: 24vw;
      width: 70.1vw;
      transform: translateX(-50%);

      &Badge{
        display: block;
        position: absolute;
        top: -20.6vw;
        left: -3.5vw;
        width: 23vw !important;
        z-index: 2;
        animation: float_badge_sp 2s linear infinite;
      }

      a{
        width: 100%;
        height: 14.93vw;
        font-size: 4.8vw;
        line-height: 14.93vw;
        border-radius: 8vw;

        span{
          top: 3.2vw;
          left: 3.73vw;
          width: 8.53vw;
          height: 8.53vw;
          font-size: 5.6vw;
        }

        &:hover{
          color: $color-black;
          background-color: $color-yellow;

          span{
            color: $color-black;
          }
        }
      }
    }

    &Topic {
      position: absolute;
      bottom: 10px;
      width: 100%;
      font-size: 3.8vw;
    }

    &Images{
      & > img:nth-child(1){
        top: -4.8vw;
        left: -17vw;
        width: 60.5vw;
      }

      & > img:nth-child(2){
        bottom: 34vw;
        right: -22.2vw;
        width: 45.8vw;
      }

      & > img:nth-child(3){
        display: none;
      }

      & > img:nth-child(4){
        display: none;
      }
    }
  }
}
