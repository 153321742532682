@import "../styles/global.module";

.lRecommend{
  @extend %clearfix;

  .headline {
    font-weight: bold;
  }

  &UserVoice{
    position: relative;
    margin-top: 64px;

    li{
      p{
        position: relative;
        padding: 1.2em 1.2em 1em;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        line-height: 1.5;
        background-color: $color-yellow;
        border-radius: 8px;

        &::before{
          position: absolute;
          left: 50%;
          bottom: -32px;
          display: block;
          content: "";
          border: 16px solid transparent;
          border-top-color: $color-yellow;
          transform: translateX(-50%) scaleX(0.7);
        }
      }

      figure{
        display: block;
        margin: 30px auto 0;
        width: 164px;
        height: 164px;
        border: 3px solid $color-black;
        background-color: $color-yellow-lighten;
        border-radius: 100px;
      }

      &:nth-child(2){
        p{
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 30%;
          transform: translateX(-50%);

          &::before{
            top: -32px;
            bottom: auto;
            border: 16px solid transparent;
            border-bottom-color: $color-yellow;
          }
        }

        figure{
          margin-top: 0;
        }
      }
    }
  }

  &Product{
    ul{
      @extend %clearfix;

      li{
        float: left;
        width: 50%;

        &:first-child{
          width: 100%;
        }

        &:nth-child(6){
          margin-bottom: 0;
        }
      }
    }
  }

  .clm2{
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;

    h3 {
      font-weight: bold !important;
    }
  }

  .listCard{
    ul{
      padding: 32px 28px;
    }
  }

  @include mqMax($break-l){
    &UserVoice{
      li{
        p{
          font-size: 13px;
        }
      }
    }
  }

  @include mqMax($break-m){
    &UserVoice{
      margin-top: 8vw;

      li{
        margin-right: 4%;
        width: 48%;

        p{
          font-size: 1.9vw !important;

          &::before{
            bottom: -4vw;
            border: 2.2vw solid transparent;
            border-top-color: $color-yellow;
          }
        }

        figure{
          margin: 3.5vw auto 6vw;
          width: 20vw;
          height: 20vw;
          border-radius: 38.6vw;
          border-width: 4px;
        }

        &:first-child{
          float: none;
          margin: 0 auto;
        }

        &:last-child{
          margin-right: 0;
        }

        &:nth-child(2){
          p{
            position: static;
            left: auto;
            bottom: auto;
            width: 100%;
            transform: translateX(0);

            &::before{
              top: auto;
              bottom: -4vw;
              border: 2.2vw solid transparent;
              border-top-color: $color-yellow;
            }
          }

          figure{
            margin: 3.5vw auto 6vw;
          }
        }
      }
    }

    &Person{
      margin-bottom: 6vw;

      ul{
        li{
          &:nth-child(3){
            margin-bottom: 0;
          }
        }
      }
    }

    &Product{
      ul{
        li{
          float: none;
          width: 100%;

          &:first-child{
            width: 100%;
          }

          &:nth-child(6){
            margin-bottom: 1em;
          }
        }
      }
    }

    .clm2{
      margin-top: 5vw;
    }

    .listCard{
      ul{
        padding: 5vw;
      }
    }
  }
  @include mqMax($break-s){
    &UserVoice{
      li{
        float: none;
        width: 100%;

        p{
          font-size: 3.8vw !important;

          &::before{
            bottom: -7.5vw;
            border: 4vw solid transparent;
            border-top-color: $color-yellow;
          }
        }

        figure{
          margin: 6.4vw auto 10vw;
          width: 38.6vw;
          height: 38.6vw;
          border-width: 3px;
        }

        &:nth-child(2){
          p{
            &::before{
              bottom: -7.5vw;
              border: 4vw solid transparent;
              border-top-color: $color-yellow;
            }
          }

          figure{
            margin: 6.4vw auto 10vw;
          }
        }
      }
    }

    .listCard{
      ul{
        padding: 7vw 5.5vw;
      }
    }
  }
}
