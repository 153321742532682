@import '../styles/global.module';

.lFooter{
  @extend %clearfix;

  padding: 56px 8.18%;

  &Copyright{
    display: block;
    float: left;
    font-family: $font-en;
    font-size: 15px;
    font-weight: 700;
  }

  &Nav{
    @extend %clearfix;

    float: right;

    li{
      float: left;
      margin-left: 2em;

      a{
        text-decoration: underline;
        transition: $base-transition;

        &:hover{
          color: $color-orange;
        }
      }
    }
  }

  @include mqMax($break-m){
    padding: 10vw 8.53vw 8vw;

    &Copyright{
      float: none;
      text-align: center;
      font-size: 2.2vw;
    }

    &Nav{
      @extend %clearfix;

      margin-bottom: 3em;
      float: none;
      font-size: 2.2vw;
      text-align: center;

      li{
        float: none;
        display: inline-block;
        margin: 0 0.7em;
      }
    }
  }

  @include mqMax($break-s){
    padding: 13vw 8.53vw 10vw;

    &Copyright{
      font-size: 3.8vw;
    }

    &Nav{
      margin-bottom: 4em;
      font-size: 3.2vw;
    }
  }
}
