@mixin spriteWidth($sprite,$scale:1) {
  width: nth($sprite, 5) * $scale;
}
@mixin spriteHeight($sprite,$scale:1) {
  height: nth($sprite, 6) * $scale;
}
@mixin spritePosition($sprite,$scale:1) {
  $sprite-offset-x: nth($sprite, 3) * $scale;
  $sprite-offset-y: nth($sprite, 4) * $scale;

  background-position: $sprite-offset-x  $sprite-offset-y;
}
@mixin spriteImage($sprite) {
  $sprite-image: nth($sprite, 9);

  background-image: url(#{$spriteImage});
  background-repeat: no-repeat;
}
@mixin spriteSize($sprite,$scale:1) {
  background-size: nth($sprite, 7) *$scale nth($sprite, 8) *$scale;
}
@mixin sprite($sprite,$scale:1) {
  display: inline-block;
  @include spriteImage($sprite);
  @include spritePosition($sprite,$scale);
  @include spriteWidth($sprite,$scale);
  @include spriteHeight($sprite,$scale);
  @include spriteSize($sprite,$scale);
}

@mixin mqMax($width){
  @media screen and (max-width:$width){
    @content;
  }
}
@mixin mqMin($width){
  @media screen and (min-width:$width){
    @content;
  }
}
@mixin mqHeight($height){
  @media screen and (max-height:$height) and (min-width:768px){
    @content;
  }
}

@mixin placeholder($color) {
  &::placeholder {
    color: $color;
  }

  &:input-placeholder {
    color: $color;
  }

  &::input-placeholder {
    color: $color;
  }
}

%clearfix {
  *zoom: 1;

  &::before, &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}
