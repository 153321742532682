@import "../styles/global.module";

.lCompare{
  position: relative;
  padding-bottom: 32px;

  &::before{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100vw;
    height: 100%;
    background-color: $color-yellow;
  }

  .headline{
    position: relative;
    margin-bottom: 32px;
    font-weight: 700;
    line-height: 52px;

    span {
      display: inline;
      margin-top: 0;

      &.wrapper{
        position: relative;
        // underline が途切れるため
        margin-left: -1px;
      }

      &.content{
        position: absolute;
        top: -34px;
        left: 28px;
        width: 74px;
        transform: translateX(-25%);
        font-size: 12px;
      }

      &.underLine {
        text-decoration: underline solid 8px $color-white;
        text-underline-offset: -4px;
      }
    }
  }

  & > h2 {
    position: relative;
  }

  & > p {
    position: relative;
    text-align: center;
  }

  &Example{
    position: relative;
    background-color: $color-white;
    border-radius: 16px;

    & > h3 {
      background-color: $color-yellow-lighten;
      text-align: center;
      padding: 23px 0 17px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      font-size: 18px;
      font-weight: 700;
    }

    ul {
      padding: 26px 10vw 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        display: inline;

        p {
          display: inline;
        }

        p:last-child {
          font-weight: 700;
        }
      }
    }
  }

  &Flow{
    $icon-length: 164px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 82px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;

    li.listCard{
      position: relative;
      width: 48%;
      background-color: $color-white;

      &.self{
        border-color: $color-yellow;
      }
    }

    &Wrapper{
      display: flex;
      padding: 32px 32px 0;
    }

    &IconWrapper {
      position: relative;
      width: $icon-length;
      height: $icon-length;
    }

    &Icon{
      position: absolute;
      top: -(calc($icon-length / 2));
      width: $icon-length;
      height: $icon-length;
      background-color: $color-yellow;
      border-radius: calc($icon-length / 2);
      border: 3px solid $color-black;

      &.self {
        border: none;
        background-color: $color-yellow-lighten;
      }

      .inner {
        position: relative;
        height: 100%;
        text-align: center;
      }

      h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 100%;
        padding: 0;
        background-color: transparent;
        border: none;
        line-height: 1.5;
      }
    }

    &Description{
      text-align: left;
      margin-left: 32px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      padding-bottom: 30px;

      &.self{
        padding-bottom: 34px;
      }

      & > h4 {
        margin-bottom: 30px;
        font-size: 22px;
        font-weight: 700;

        span {
          font-size: 16px;
        }
      }

      & > ul {
        font-size: 15px;
        font-weight: normal;

        li {
          strong {
            z-index: 1;
            position: relative;
            color: #f08300;

            &::before {
              position: absolute;
              bottom: 0.05em;
              left: 0;
              display: inline;
              content: "";
              width: 100%;
              height: 3px;
              background-color: #fed900;
              z-index: -1;
            }
          }
        }
      }
    }

    &PriceSummary{
      position: relative;
      background-color: $color-yellow-lighten;
      border-radius: 16px;
      margin: 0 32px 32px;
      padding: 32px;
      font-weight: 700;

      .formula{
        font-size: 16px;
      }

      .price{
        font-family: $font-en;
        font-size: 40px;
        font-weight: 600;
        line-height: 1;

        span{
          font-family: $font-ja;
          font-size: 18px;
        }
      }

      & > svg {
        position: absolute;
        right: -42px;
        bottom: 32px;
        width: 121px;
        height: 132px;
      }
    }
  }

  @include mqMax($break-l){
    &Flow {
      li.listCard {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 60px;
        }
      }
    }
  }

  @include mqMax($break-m){
    &Flow{
      margin-top: 10vw;
      font-size: 2.4vw;

      li{
        &::after{
          top: -0.2em;
          font-size: 8vw;
        }
      }
    }
  }
  @include mqMax($break-s){
    .headline{
      line-height: 33px;

      span {
        &.content{
          display: inline-block;
          position: relative;
          top: -4px;
          left: 16px;
        }

        &.underLine {
          text-decoration: underline solid 6px $color-white;
          text-underline-offset: -3px;
        }
      }
    }

    &Example{
      & > h3 {
        padding: 18px 16px;
        font-size: 16px;
      }
    }

    &Flow{
      font-size: 3.2vw;

      li.listCard {
        &:not(:last-child) {
          margin-bottom: 52px;
        }
      }

      &IconWrapper {
        width: 36px;
        height: 0;
      }

      &Icon{
        top: -50px;
        left: -50px;
        width: 100px;
        height: 100px;

        svg {
          height: 60px;
          width: 60px;
        }
      }

      &Description{
        & > h4 {
          font-size: 18px;
          span {
            font-size: 12px;
          }
        }
        & > ul {
          margin-left: -68px;
        }
      }

      &PriceSummary{
        padding: 32px 0;

        .formula{
          font-size: 14px;
        }

        .price{
          font-family: $font-en;
          font-size: 32px;
          font-weight: 600;
          line-height: 1;

          span{
            font-family: $font-ja;
            font-size: 18px;
          }
        }

        & > svg {
          position: absolute;
          right: -40px;
          bottom: 81px;
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

.lCompareFooter{
  $triangle-height: 110px;

  position: relative;
  padding-top: 36px + $triangle-height;
  padding-bottom: 54px;

  &::before{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100vw;
    height: 100%;
    background-color: $color-orange;
  }

  &Triangle{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $triangle-height;

    &Wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: $color-yellow;
    }

    &Contents {
      position: absolute;
      width: 100%;
      height: 200%;
      top: 0;
      left: 0;

      &.left {
        background-image: linear-gradient(to right top, $color-orange 0%, $color-orange 50%, transparent 50%);
      }

      &.right {
        background-image: linear-gradient(to left top, $color-orange 0%, $color-orange 50%, transparent 50%);
      }
    }
  }

  &Contents{
    position: relative;

    & > .headline {
      color: $color-white;
      line-height: 1;
      font-size: 20px;

      & > strong {
        display: inline-block;
        font-size: 56px;
        font-family: $font-en;

        &:before {
          bottom: 4px;
        }
      }
    }
  }
}
