@import "./setting.module";
@import "./mixin.module";

.position {
    &Relative {
        position: relative;
    }
}

.width {
  &Auto {
    width: auto !important;
  }

  &Percent100 {
    width: 100% !important;
  }
}

.margin {
  &Bottom0 {
    margin-bottom: 0 !important;
  }

  &Right0 {
    margin-right: 0 !important;
  }
}

.padding {
  &Top0 {
    padding-top: 0 !important;
  }

  &Top8 {
    padding-top: 8px !important;
  }
}

.display {
  &Flex {
    display: flex;
  }

  &Block {
    display: block !important;
  }

  &InlineBlock {
    display: inline-block !important;
  }
}

.text {
  &Align {
    &Center {
      text-align: center;
    }

    &Right {
      text-align: right;
    }
  }
}

.font {
  &Weight {
    &Bold {
      font-weight: bold;
    }
  }

  &Size {
    &20 {
      font-size: 20px;
    }
  }
}

.flex {
  &JustifyContent {
    &SpaceBetween {
      justify-content: space-between;
    }
  }
}

.button {
  & > button {
    padding: 12px 0 !important;
    border-radius: 8px !important;
    text-align: center !important;
    font-weight: bold !important;
    font-size: 20px !important;
    color: #FFF !important;
  }

  & > button,
  &Item {
    padding: 12px 0;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #FFF;
    transition: $base-transition;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    &:not(:last-child) {
      margin-right: 32px;
    }
  }

  &Go {
    background-color: #F08300 !important;
  }

  &Back {
    flex-basis: 164px;
    background-color: #A7A7A7 !important;
  }
}

.link {
  padding: 12px 0;
  text-align: center;

  a {
    transition: $base-transition;

    &:hover {
      cursor: pointer;
    }
  }

  &Back {
    font-size: 14px;
    color: #000;

    &:hover {
      opacity: 0.6;
    }
  }

  &Edit {
    display: block;
    width: 96px;
    padding: 4px 0;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    font-size: 15px;
    background-color: #FED900;
    color: #000;
    transition: $base-transition;

    &:hover {
      opacity: 0.6;
    }

    &::after {
      position: relative;
      left: 4px;
      content: "";
      display: inline-block;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #000;
    }
  }
}

.text {
  &Align {
    &Center {
      text-align: center;
    }
  }
}

.underLine {
  &Yellow {
    &::before {
      position: absolute;
      bottom: 0.5em;
      left: 0;
      display: inline;
      content: "";
      width: 100%;
      height: 6px;
      background-color: #fed900;
      z-index: -1;
    }
  }
}

@include mqMax($break-l) {
  .button {
    & > button {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}
