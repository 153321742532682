//antialiased,subpixel-antialiased,none
$font-smoothing: antialiased;

//auto,optimizeSpeed,optimizeLegibility,geometricPrecision
$text-rendering: auto;
$color-black: #000;
$color-white: #fff;
$color-yellow: #fed900;
$color-yellow-darken: #d5ac00;
$color-yellow-lighten: #fcf7d5;
$color-orange: #f08300;
$color-orange-darken: #f06f00;
$color-orange-lighten: #f99f32;
$color-grey: #adadad;
$color-grey-darken: #adadad;
$color-grey-lighten: #e8e8e8;
$color-red: #ff1100;
$color-error: #dd4c4C;
$color-error-lighten: #ffd8d8;
$base-font-family: arial, "Noto Sans JP", sans-serif;
$base-font-family-sp: arial, "Noto Sans JP", sans-serif;

// $base-font-family: Arial, "Yu Gothic", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Meiryo", sans-serif;
// $base-font-family-sp: Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

$base-font-size: 15px;
$base-font-size-sp: 3.8vw;
$base-font-color: $color-black;
$base-background-color: $color-white;
$base-line-height: 2;
$base-letter-spacing: 0.06em;
$base-transition: ease .3s;
$break-l: 1024px;
$break-m: 834px;
$break-ms: 768px;
$break-s: 568px;
$break-ss: 320px;
$font-en: "Montserrat", serif;
$font-ja: $base-font-family;
$font-ja-sp: $base-font-family-sp;
