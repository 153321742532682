@import "src/components/lp/styles/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.accordion{
  &Label,
  &Content{
    position: relative;
    padding: 1.5em 2em 1.5em 5.5em;
    font-size: 18px;
    background-color: $color-white;

    span{
      position: absolute;
      left: 1.5em;
      font-family: $font-en;
      font-size: 24px;
      font-weight: 700;
    }
  }

  &Label{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &Content{
    margin-bottom: 32px;
    border-top: 3px dashed $color-grey-lighten;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  @include mqMax($break-m){
    &Label,
    &Content{
      padding: 1em 1em 1em 4em;
      font-size: 2.8vw;

      span{
        left: 1em;
        font-size: 3.8vw;
      }
    }

    &Content{
      margin-bottom: 1.5em;
    }
  }

  @include mqMax($break-s){
    &Label,
    &Content{
      padding: 1em 1em 1em 4em;
      font-size: 3.8vw;

      span{
        left: 1em;
        font-size: 5vw;
      }
    }

    &Content{
      margin-bottom: 1.5em;
    }
  }
}
