@import '../styles/global.module';

.lFaq{
  position: relative;

  &::before{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100vw;
    height: 100%;
    background-color: $color-yellow-lighten;
  }

  &>h2{
    position: relative;

    &.headline {
      margin-bottom: 62px;
      font-weight: bold;
    }
  }

  @include mqMax($break-m){
  }
  @include mqMax($break-s){
  }
}
