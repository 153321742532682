@import "../styles/global.module";

.lPlan{
  padding-top: 0;
  padding-bottom: 120px;

  .headline {
    font-weight: bold;
  }

  .leadSentence{
    margin-bottom: 52px;
  }

  .underLine {
    text-decoration: underline solid 4px #FED900;
    z-index: 1;
  }

  p{
    text-align: center;

    &.note {
      font-size: 13px;
      text-align: left;
      text-indent: -1.4em;
      margin-left: 1.4em;
    ;
    }
  }

  &Price{
    margin-top: 48px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 3px solid $color-black;
    border-radius: 16px;
    font-weight: 700;
    text-align: center;

    th,td{
      border-right: 3px solid $color-black;
      border-bottom: 3px solid $color-black;
      width: 25%;
      height: 88px;

      &.basic{
        background-color: $color-yellow;
      }

      &.self{
        background-color: $color-white;
      }

      &.accent{
        color: $color-orange;
        background-color: $color-yellow-lighten;

        &Indent {
          text-indent: 1em;
        }

        em{
          font-size: 36px;
        }

        strong{
          font-size: 65px;
          font-weight: 600;

          &.light {
            font-size: 50px;
          }
        }
      }
    }

    &Note {
      line-height: 1.4em;
      font-size: 11px;
      font-family: $font-ja;
    }

    td{
      &:last-child{
        border-right: none;
      }
    }

    thead{
      font-size: 22px;

      span{
        display: block;
        font-size: 15px;
      }

      strong{
        font-size: 26px;
        font-family: $font-en;
      }
    }

    tbody{
      tr{
        &:last-child{
          th,td{
            border-bottom: none;
          }
        }
      }

      th{
        font-size: 20px;

        span{
          display: block;
          font-size: 11px;
        }
      }

      td{
        font-size: 26px;
        font-weight: 600;
        font-family: $font-en;

        small{
          font-size: 12px;
          font-family: $font-ja;
        }

        &:nth-child(3) {
          line-height: 0.8;
        }

        &:last-child{
          font-size: 22px;
        }
      }
    }
  }

  &Table{
    margin-bottom: 18px;
  }

  @include mqMax($break-l){
  }

  @include mqMax($break-m){
    padding-bottom: 8vw;

    p{
      text-align: left;
    }

    &Table{
      overflow-x: scroll;
      white-space: pre;
      width: 91.47vw;

      &Inner{
        white-space: normal;
        padding-right: 8.53vw;
        width: 122.4vw;
      }
    }

    &Price{
      margin-top: 0;

      th,td{
        width: 25%;
        height: 13vw;

        &.accent{
          em{
            font-size: 3.8vw;
          }

          strong{
            font-size: 10vw;
          }
        }
      }

      thead{
        font-size: 2.4vw;

        span{
          font-size: 2.2vw;
        }

        strong{
          font-size: 4vw;
        }

        th,td{
          height: 11vw;
        }
      }

      tbody{
        th{
          font-size: 2.8vw;

          span{
            font-size: 1.5vw;
          }
        }

        td{
          font-size: 3.7vw;

          small{
            font-size: 1.5vw;
          }

          &:last-child{
            font-size: 3vw;
          }
        }
      }
    }
  }
  @include mqMax($break-s){
    &Table{
      margin-bottom: 14px;

      &Inner{
        p{
          margin-top: 0.5em;
          font-size: 2.5vw;
        }
      }
    }

    &Price{
      border-radius: 8px;
      border-width: 2px;

      th,td{
        height: 18vw;
        border-width: 2px;

        &.grey{
          border-top-right-radius: 8px;
        }

        &.accent{
          em{
            font-size: 3.8vw;
          }

          strong{
            font-size: 10vw;
          }
        }
      }

      thead{
        font-size: 3.2vw;

        span{
          font-size: 2.8vw;
        }

        strong{
          font-size: 5.2vw;
        }

        th,td{
          height: 16vw;
        }
      }

      tbody{

        th, td {
          font-size: 12px !important;
        }

        th{
          font-size: 3.8vw;

          span{
            font-size: 2.5vw;
          }
        }

        td{
          font-size: 4.5vw;

          strong {
            font-size: 29px !important;
          }

          small{
            font-size: 1.5vw;
          }

          &:last-child{
            font-size: 3.8vw;
          }
        }
      }
    }
  }
}
