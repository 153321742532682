@import "src/components/lp/styles/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.clm{
  &2{
    @extend %clearfix;

    > li{
      float: left;
      margin-right: 4%;
      width: 48%;

      &:nth-child(2n){
        margin-right: 0;
      }

      &:nth-child(2n+1){
        clear: left;
      }
    }
    @include mqMax($break-m){
      > li{
        float: none;
        margin-right: 0;
        width: 100%;
      }
    }
  }

  &3{
    @extend %clearfix;

    > li{
      float: left;
      margin-right: 5%;
      width: 30%;

      &:nth-child(3n){
        margin-right: 0;
      }

      &:nth-child(3n+1){
        clear: left;
      }
    }
  }

  &4{
    @extend %clearfix;

    > li{
      float: left;
      margin-right: 4%;
      width: 22%;

      &:nth-child(4n){
        margin-right: 0;
      }

      &:nth-child(4n+1){
        clear: left;
      }
    }
    @include mqMax($break-m){
      > li{
        margin-right: 4%;
        width: 48%;

        &:nth-child(2n){
          margin-right: 0;
        }

        &:nth-child(2n+1){
          clear: left;
        }

        &:nth-child(1),
        &:nth-child(2){
          margin-bottom: 7vw;
        }
      }
    }
    @include mqMax($break-s){
      > li{
        &:nth-child(1),
        &:nth-child(2){
          margin-bottom: 9.6vw;
        }
      }
    }
  }
}
