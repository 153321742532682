@import "src/components/lp/styles/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.listCard{
  border: 3px solid $color-black;
  border-radius: 16px;

  h3{
    padding: 0.8em 0 0.6em;
    font-weight: 700;
    text-align: center;
    line-height: 1;
    background-color: $color-yellow-lighten;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: 3px solid $color-black;
  }

  ul{
    li{
      position: relative;
      margin-bottom: 1em;
      padding-left: 1.4em;
      line-height: 1.6;

      &::before{
        position: absolute;
        top: 0.25em;
        left: 0;
        display: block;
        content: "";
        width: 1em;
        height: 1em;
        border-radius: 1em;
        background-color: $color-yellow;
      }

      &:last-child{
        margin-bottom: 0;
      }

      &.devicePc{
        &::before{
          display: none;
        }
      }
    }
  }
  @include mqMax($break-l){
    ul{
      li{
        font-size: 12px;

        &.devicePc{
          display: none;
        }
      }
    }
  }
  @include mqMax($break-m){
    border-width: 4px;

    h3{
      padding: 1em 0 0.8em;
      border-width: 4px;
    }

    ul{
      li{
        font-size: 2.5vw !important;;
      }
    }
  }
  @include mqMax($break-s){
    border-width: 3px;

    h3{
      border-width: 3px;
    }

    ul{
      li{
        font-size: 3.8vw !important;;
      }
    }
  }
}
