@import "src/components/lp/styles/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

.lpWrapper{
  text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: $font-smoothing;
  font-smoothing: $font-smoothing;
  text-rendering: $text-rendering;
  font-size: $base-font-size;
  font-family: $font-ja;
  font-weight: normal;
  color: $base-font-color;
  letter-spacing: $base-letter-spacing;
  background-color: $base-background-color;
  font-feature-settings : "palt";

  .safari &{
    font-feature-settings : "pkna";
  }
  @include mqMax($break-m){
    font-feature-settings : normal;

    .safari &{
      font-feature-settings : normal;
    }
  }

  @include mqMax($break-m){
    font-family: $font-ja-sp;
    font-size: 2.5vw;
    font-weight: normal;
  }
  @include mqMax($break-ms){
    font-size: 3vw;
  }
  @include mqMax($break-s){
    font-size: 3.8vw;
  }

  p{
    line-height: $base-line-height;

    strong{
      position: relative;

      &::before{
        position: absolute;
        bottom: 0.01em;
        left: 0;
        display: inline;
        content: "";
        width: 100%;
        height: 6px;
        background-color: $color-yellow;
        z-index: -1;
      }
    }
  }

  a{
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }

  button {
    &:hover {
      cursor: pointer;
    }
  }

  img{
    width: 100%;
  }
}
